import axios from "axios";

let MailHelper = {
  sendEmail: async (mailInfo) => sendEmail(mailInfo),
};

// send sms
async function sendEmail(mailInfo) {
  //

  axios
    .post("http://onelink2pay.co:8000/mail2", {
      from: "mahmoud.hamdy@cloudx9.io",
      subject: "The subject field is required.",
      text: "The text field is required.",
      to: "ahmednabilgm7@gmail.com",
    })
    .then(function (response) {
      // handle success
      //    var ss = response.data.responses
      return response;
      console.log("dataaaaaa", response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
}
// send sms

export default MailHelper;
