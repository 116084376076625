import React, { Component } from "react";
import { Grid, Row, Col, Tabs, Tab } from "react-bootstrap";
import AdminUsers from "components/Admin/Users";
import AddUserAdmin from "components/Admin/AddUser";
class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_addFile: true
    };
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Tabs defaultActiveKey="user" id="uncontrolled-tab-example">
                <Tab eventKey="user" title="Users">
                  <br />
                  <AdminUsers />
                </Tab>
                <Tab eventKey="file" title="Add User">
                  <br />
                  <AddUserAdmin />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Notifications;
