import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card";
import Misc from "../../Services/Misc.js";
import { Alert } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import CustomAlert from "components/CustomAlert/CustomAlert";
export class AddMerchant extends Component {
  notificationSystem = React.createRef();

  addNotification = (event) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: <h6>Sorry</h6>,
      message: <span>the email is already exist</span>,
      level: "error",
      position: "tc",
      autoDismiss: 5,
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      currency: "",
      megs_merchantID: "",
      megs_merchantName: "",
      megs_apiUsername: "",
      megs_apiPassword: "",
      megs_returnUrl: "",
      upg_mID: "",
      upg_tID: "",
      upg_secureHash: "",
      upg_returnUrl: "",
      address_line1: "",
      address_line2: "",
      mobile_number: "",
      isFailed: false,
      alertTitle: "",
      alertDesc: "",
      isLoading: 0,
    };
  }
  add() {
    if (this.state.email === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merchant email",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.name === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant name",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.address_line1 === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant address line 1",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.address_line2 === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant address line 2",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.upg_mID === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant UPG ID",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.upg_tID === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant UPG terminal ID",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.upg_secureHash === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant UPG secure hash",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.mobile_number === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant mobile number",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.currency === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant currency",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.megs_merchantID === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant MGS ID",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.megs_merchantName === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant MGS name",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.megs_apiUsername === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant MGS API username",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.megs_apiPassword === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the merhcant MGS API password",
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else {
      let data = {
        name: this.state.name,
        email: this.state.email,
        currency: this.state.currency,
        megs_merchantID: this.state.megs_merchantID,
        megs_merchantName: this.state.megs_merchantName,
        megs_apiUsername: this.state.megs_apiUsername,
        megs_apiPassword: this.state.megs_apiPassword,
        megs_returnUrl: this.state.megs_returnUrl,
        upg_mID: this.state.upg_mID,
        upg_tID: this.state.upg_tID,
        upg_secureHash: this.state.upg_secureHash,
        upg_returnUrl: this.state.upg_returnUrl,
        address_line1: this.state.address_line1,
        address_line2: this.state.address_line2,
        mobile_number: this.state.mobile_number,
      };
      Misc.createMerchant(data)
        .then((res) => {
          console.log(res);
          this.setState({
            isLoading: 1,
          });
          window.location.reload(true);
        })
        .catch((err) => {
          if (err.message === "GraphQL error: email already exists") {
            this.setState({
              alertTitle: "Sorry",
              alertDesc: "email already exists",
              isFailed: true,
            });
            setTimeout(() => {
              this.setState({ isFailed: false });
            }, 3000);
          } else if (err.message === "GraphQL error: phone already exists") {
            this.setState({
              alertTitle: "Sorry",
              alertDesc: "mobile number already exists",
              isFailed: true,
            });
            setTimeout(() => {
              this.setState({ isFailed: false });
            }, 3000);
          }
          console.log(err.message);
        });
    }
  }
  render() {
    return (
      <Card
        title="Add Merchant"
        content={
          <form>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Name"
                    name="name"
                    onChange={(e) =>
                      this.setState({
                        name: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>E-mail</label>
                  <input
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Merchant E-mail"
                    name="email"
                    onChange={(e) =>
                      this.setState({
                        email: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Currency</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Currency"
                    name="currency"
                    onChange={(e) =>
                      this.setState({
                        currency: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Address Line 1</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Address Line 1"
                    name="address_line1"
                    onChange={(e) =>
                      this.setState({
                        address_line1: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Merchant Address Line 2</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Address Line 2"
                    name="address_line2"
                    onChange={(e) =>
                      this.setState({
                        address_line2: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Merchant Mobile Number"
                    name="mobile_number"
                    onChange={(e) =>
                      this.setState({
                        mobile_number: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Mgs Merchant ID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Mgs Merchant ID"
                    name="megs_merchantID"
                    onChange={(e) =>
                      this.setState({
                        megs_merchantID: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Mgs Merchant Name</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Megs Merchant Name"
                    name="megs_merchantName"
                    onChange={(e) =>
                      this.setState({
                        megs_merchantName: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Mgs Api Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Mgs Api Username"
                    name="megs_apiUsername"
                    onChange={(e) =>
                      this.setState({
                        megs_apiUsername: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Mgs Api Password</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Mgs Api Password"
                    name="megs_apiPassword"
                    onChange={(e) =>
                      this.setState({
                        megs_apiPassword: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>

            <div className="row">
              {/* <div className="col-md-4">
                <div className="form-group">
                  <label>Mgs Return URL</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant Mgs Return URL"
                    name="megs_returnUrl"
                    onChange={e =>
                      this.setState({
                        megs_returnUrl: String(e.target.value)
                      })
                    }
                  ></input>
                </div>
              </div> */}
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>UPG ID</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Merchant UPG ID"
                    name="upg_mID"
                    onChange={(e) =>
                      this.setState({
                        upg_mID: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>UPG Terminal ID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant UPG Terminal ID"
                    name="upg_tID"
                    onChange={(e) =>
                      this.setState({
                        upg_tID: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>UPG Secure Hash</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Merchant UPG Secure Hash"
                    name="upg_secureHash"
                    onChange={(e) =>
                      this.setState({
                        upg_secureHash: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="form-group">
                  <label>UPG Return URL</label>
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Merchant UPG Return URL"
                    name="upg_returnUrl"
                    onChange={e =>
                      this.setState({
                        upg_returnUrl: String(e.target.value)
                      })
                    }
                  ></input>
                </div>
              </div> */}
            </div>

            {this.state.isLoading === 1 && (
              <Alert bsStyle="success">
                <button type="button" aria-hidden="true" className="close">
                  &#x2715;
                </button>
                <span>
                  <b> Success - </b> This merchant added successfully
                </span>
              </Alert>
            )}
            {this.state.isFailed === true && (
              <CustomAlert
                title={this.state.alertTitle}
                desc={this.state.alertDesc}
                type="danger"
              />
            )}
            <Button
              bsStyle="info"
              pullRight
              fill
              onClick={() => {
                this.add();
              }}
            >
              Add
            </Button>

            <div className="clearfix" />
            <NotificationSystem
              ref={this.notificationSystem}
              style={{
                NotificationItem: {
                  DefaultStyle: {
                    borderRadius: 5,
                  },
                },
              }}
            />
          </form>
        }
      />
    );
  }
}

export default AddMerchant;
