import React, { Component } from "react";
import { Grid, Row, Col, Tabs, Tab } from "react-bootstrap";
import { AddTrans } from "components/AddTrans/AddTrans";
import AddTransFile from "components/AddTransFile/AddTransFile";
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_addFile: true
    };
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Tabs defaultActiveKey="trans" id="uncontrolled-tab-example">
                <Tab eventKey="trans" title="Add Transaction">
                  <br />
                  <AddTrans />
                </Tab>
                <Tab eventKey="file" title="Add File Of Transactions">
                  <br />
                  <AddTransFile />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserProfile;
