import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";

class AdminNavbarLinks extends Component {
  render() {
    return (
      <div>
        <Nav>
          {/* <NavItem eventKey={1} href="#">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem> */}
        </Nav>
        <Nav pullRight>
          {this.props.isAdmin && (
            <NavItem eventKey={1} href="#">
              {localStorage.getItem("user_first_name")}{" "}
              {localStorage.getItem("user_last_name")}
            </NavItem>
          )}
          {!this.props.isAdmin && (
            <NavItem eventKey={1} href="#">
              {localStorage.getItem("admin_first_name")}{" "}
              {localStorage.getItem("admin_last_name")}
            </NavItem>
          )}
          <NavItem
            eventKey={3}
            onClick={() => {
              console.log(this.props.isAdmin);
              if (this.props.isAdmin === true) {
                localStorage.setItem("admin_token", "");
              } else {
                localStorage.setItem("token", "");
              }

              window.location.reload(true);
            }}
          >
            Log out
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
