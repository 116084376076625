import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { NEXUS_URL } from "./const";
const updateHeaders = operation => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  });
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : ""
    }
  };
});

const httpLink = createHttpLink({
  uri: NEXUS_URL
});
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  headers: updateHeaders,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__)
});
