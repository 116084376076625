import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card";
import Misc from "../../Services/Misc.js";
import { Alert } from "react-bootstrap";
import CustomAlert from "components/CustomAlert/CustomAlert";
export class AddUserAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      mobile_number: "",
      merchant: "",
      isLoading: 0,
      isFailed: false,
      alertTitle: "",
      alertDesc: "",
      merchantName: "Please Select Merchant",
      merchantsArr: []
    };
  }
  componentDidMount() {
    Misc.getMerchants()
      .then(res => {
        this.setState({
          merchantsArr: res
        });
      })
      .catch(err => {});
  }
  add() {
    if (this.state.email === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the email",
        isFailed: true
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.first_name === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the first name",
        isFailed: true
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.last_name === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the last name",
        isFailed: true
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.merchantName === "Please Select Merchant") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "select the merchant",
        isFailed: true
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else if (this.state.mobile_number === "") {
      this.setState({
        alertTitle: "Please",
        alertDesc: "Enter the mobile number",
        isFailed: true
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
    } else {
      let data = {
        first_name: this.state.first_name,
        email: this.state.email,
        last_name: this.state.last_name,
        password: this.state.password,
        merchant: this.state.merchant,
        mobile_number: this.state.mobile_number
      };
      Misc.createUser(data)
        .then(res => {
          console.log(res);
          this.setState({
            isLoading: 1
          });
          window.location.reload(true);
        })
        .catch(err => {
          console.log(err.message);
          debugger;
          if (err.message === "GraphQL error: email already exists") {
            this.setState({
              alertTitle: "Sorry",
              alertDesc: "email already exists",
              isFailed: true
            });
            setTimeout(() => {
              this.setState({ isFailed: false });
            }, 3000);
          } else if (err.message === "GraphQL error: phone already exists") {
            this.setState({
              alertTitle: "Sorry",
              alertDesc: "mobile number already exists",
              isFailed: true
            });
            setTimeout(() => {
              this.setState({ isFailed: false });
            }, 3000);
          }
        });
    }
  }
  chooseMerchant(index) {
    console.log(this.state.merchantsArr[index].id);
    this.setState({
      merchant: this.state.merchantsArr[index].id,
      merchantName: this.state.merchantsArr[index].name
    });
  }
  render() {
    return (
      <div>
        <Card
          title="Add User"
          content={
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Merchant</label>
                    <div className="dropdown">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        id="menu1"
                        data-toggle="dropdown"
                      >
                        {this.state.merchantName}
                        <span className="caret"></span>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="menu1">
                        {this.state.merchantsArr.map((prop, key) => {
                          return (
                            <li>
                              <span onClick={prop => this.chooseMerchant(key)}>
                                {prop.name}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>E-mail</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User E-mail"
                      name="email"
                      onChange={e =>
                        this.setState({
                          email: String(e.target.value)
                        })
                      }
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter User Password"
                      name="password"
                      onChange={e =>
                        this.setState({
                          password: String(e.target.value)
                        })
                      }
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User First Name"
                      name="first_name"
                      onChange={e =>
                        this.setState({
                          first_name: String(e.target.value)
                        })
                      }
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User Last Name"
                      name="last_name"
                      onChange={e =>
                        this.setState({
                          last_name: String(e.target.value)
                        })
                      }
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User Mobile Number"
                      name="mobile_number"
                      onChange={e =>
                        this.setState({
                          mobile_number: String(e.target.value)
                        })
                      }
                    ></input>
                  </div>
                </div>
              </div>

              {this.state.isLoading === 1 && (
                <Alert bsStyle="success">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>
                    <b> Success - </b> This user {this.state.first_name}{" "}
                    {this.state.last_name} added successfully to merchant{" "}
                    {this.state.merchantName}
                  </span>
                </Alert>
              )}
              {this.state.isFailed === true && (
                <CustomAlert
                  title={this.state.alertTitle}
                  desc={this.state.alertDesc}
                  type="danger"
                />
              )}

              <Button
                bsStyle="info"
                pullRight
                fill
                onClick={() => {
                  this.add();
                }}
              >
                Add
              </Button>

              <div className="clearfix" />
            </form>
          }
        />
      </div>
    );
  }
}

export default AddUserAdmin;
