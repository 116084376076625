import React, { Component } from "react";
import { Alert } from "react-bootstrap";
class CustomAlert extends Component {
  render() {
    return (
      <Alert bsStyle={this.props.type} style={{ borderRadius: 20 }}>
        <span>
          <b> {this.props.title} - </b> {this.props.desc}
        </span>
      </Alert>
    );
  }
}

export default CustomAlert;
