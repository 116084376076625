import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import Login from "components/Login/Login";
import Pay from "components/Pay/Pay";
import PayUPG from "components/Pay/PayUPG";
import AdminHome from "components/Admin/home";

import AdminLogin from "components/Admin/AdminLogin";
import PayTrans from "components/PayTrans/PayTrans";
import Privacy from "components/Content/privacy";
import Terms from "components/Content/terms";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !!(
        localStorage.getItem("token") &&
        localStorage.getItem("token").length > 0
      ) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const AdminRouter = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !!(
        localStorage.getItem("admin_token") &&
        localStorage.getItem("admin_token").length > 0
      ) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/admin_login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default function AuthExample() {
  return (
    <Router>
      <div>
        {/* <Route path="/bm_admin/" component={AdminHome} /> */}
        <Route path="/pay/:id" component={Pay} />
        <Route path="/pay_trans/:id" component={PayTrans} />
        <Route path="/pay_upg/:id" component={PayUPG} />
        <Route path="/terms/:id" component={PayUPG} />
        <Route path="/privacy/:id" component={Privacy} />
        <Route path="/terms/:id" component={Terms} />
        <AdminRouter
          path="/bm_admin/"
          component={(props) => <AdminHome {...props} />}
        />
        <Route path="/admin_login" exact component={AdminLogin} />
        <Route path="/login" exact component={Login} />
        <PrivateRoute
          path="/admin"
          component={(props) => <AdminLayout {...props} />}
        />
        <Route path="/" exact component={Login} />
      </div>
    </Router>
  );
}
