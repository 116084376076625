import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card";
import TransHelper from "../../Services/TransHelper";
import SmsHelper from "../../Services/SmsHelper";
import MailHelper from "../../Services/MailHelper";
import { Alert } from "react-bootstrap";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { BASE_URL } from "const";
import { CopyToClipboard } from "react-copy-to-clipboard";
export class AddTrans extends Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    mobile_whatsApp: "",
    delivery_mobile: "",
    amount: 1.0,
    notices: "",
    mgsUrl: "/",
    upgUrl: "/",
    requiredEmail: false,
    requiredFirstName: false,
    requiredLastName: false,
    requiredMobile: false,
    requiredAmount: false,
    isLoading: true,
  };
  addTrans() {
    let data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      mobile_number: this.state.mobile_number,
      mobile_whatsApp: this.state.mobile_whatsApp,
      amount: parseFloat(this.state.amount),
      notices: this.state.notices,
      delivery_mobile: this.state.delivery_mobile,
      alertTitle: "",
      alertDesc: "",
    };
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = re.test(String(this.state.email).toLowerCase());

    if (data.first_name.length === 0) {
      this.setState({
        requiredFirstName: true,
        requiredLastName: false,
        requiredEmail: false,
        requiredMobile: false,
        requiredAmount: false,
        alertTitle: "Please",
        alertDesc: "enter the first name",
      });
      setTimeout(() => {
        this.setState({ requiredFirstName: false });
      }, 5000);
    } else if (data.last_name.length === 0) {
      this.setState({
        requiredFirstName: false,
        requiredLastName: true,
        requiredEmail: false,
        requiredMobile: false,
        requiredAmount: false,
        alertTitle: "Please",
        alertDesc: "enter the last name",
      });
      setTimeout(() => {
        this.setState({ requiredLastName: false });
      }, 5000);
    } else if (data.email.length === 0 || !isValidEmail) {
      this.setState({
        requiredFirstName: false,
        requiredLastName: false,
        requiredEmail: true,
        requiredMobile: false,
        requiredAmount: false,
        alertTitle: "Please",
        alertDesc: "enter a vaild email",
      });
      setTimeout(() => {
        this.setState({ requiredEmail: false });
      }, 5000);
    } else if (data.mobile_number.length === 0) {
      this.setState({
        requiredFirstName: false,
        requiredLastName: false,
        requiredEmail: false,
        requiredMobile: true,
        requiredAmount: false,
        alertTitle: "Please",
        alertDesc: "enter the mobile number",
      });
      setTimeout(() => {
        this.setState({ requiredMobile: false });
      }, 5000);
    } else if (data.amount <= 0.0) {
      this.setState({
        requiredFirstName: false,
        requiredLastName: false,
        requiredEmail: false,
        requiredMobile: false,
        requiredAmount: true,
        alertTitle: "Please",
        alertDesc: "enter a vaild amount",
      });
      setTimeout(() => {
        this.setState({ requiredAmount: false });
      }, 5000);
    } else {
      TransHelper.createTrans(data)
        .then((res) => {
          let mobile_number = res.mobile_number;
          let name = `${res.first_name} ${res.last_name}`;
          let url_mgs = `${BASE_URL}/pay_trans/${res.id}`;
          let url_upg = `${BASE_URL}/pay_upg/${res.id}`;
          let txt = `Hello Mr/Mrs ${name} please pay amount EGP ${res.amount}\nto pay use this link ${url_mgs}`;
          MailHelper.sendEmail("AAa")
            .then((res) => {
              console.log(res, "MAIL DATA");
            })
            .then((err) => {
              console.log(err);
            });
          SmsHelper.sendSms({
            txt: txt,
            mobile_number: "002" + mobile_number,
          })
            .then((smsRes) => {
              TransHelper.updateTransSms(res.id)
                .then((res) => {
                  this.setState({
                    first_name: "",
                    last_name: "",
                    email: "",
                    mobile_number: "",
                    mobile_whatsApp: "",
                    delivery_mobile: "",
                    amount: 0.0,
                    notices: "",
                    mgsUrl: url_mgs,
                    upgUrl: url_upg,
                    isLoading: true,
                    sendingSms: 1,
                  });
                  setTimeout(() => {
                    this.setState({ sendingSms: 0 });
                  }, 15000);
                })
                .then((err) => {
                  console.warn(err);
                });
            })
            .catch((err) => {
              console.warn(err);
            });
        })
        .catch((err) => console.log(err));
    }
  }
  render() {
    return (
      <Card
        title="Add Transaction"
        content={
          <form>
            {(this.state.requiredFirstName ||
              this.state.requiredLastName ||
              this.state.requiredEmail ||
              this.state.requiredAmount ||
              this.state.requiredMobile) && (
              <CustomAlert
                title={this.state.alertTitle}
                desc={this.state.alertDesc}
                type="danger"
              />
            )}
            {this.state.sendingSms === 1 && (
              <div
                style={{
                  backgroundColor: "#f3f3f3",
                  borderWidth: 1,
                  borderRadius: 10,
                  padding: 20,
                  margin: 20,
                }}
              >
                <button
                  type="button"
                  aria-hidden="true"
                  className="close"
                  onClick={() => this.setState({ sendingSms: 0 })}
                >
                  &#x2715;
                </button>
                <br />
                <span>
                  <b> Success - </b> This payment added successfully
                </span>
                <br />
                <br />
                <br />
                <span>
                  <b> Payment Link - </b>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.mgsUrl}
                  >
                    {this.state.mgsUrl}
                  </a>
                </span>
                <CopyToClipboard
                  text={this.state.mgsUrl}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <Button bsStyle="success" pullRight fill>
                    COPY
                  </Button>
                </CopyToClipboard>

                <br />
                <br />
                <br />
              </div>
            )}
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    First Name <span style={{ color: "red" }}>(Required)</span>
                  </label>
                  <input
                    required={true}
                    type="text"
                    onKeyPress={(event) =>
                      event.charCode == 8 || event.charCode == 0
                        ? null
                        : event.charCode >= 48 && event.charCode <= 57
                    }
                    value={this.state.first_name}
                    className="form-control"
                    placeholder="Enter First Name"
                    name="first_name"
                    onChange={(e) =>
                      this.setState({
                        first_name: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    Last Name <span style={{ color: "red" }}>(Required)</span>
                  </label>
                  <input
                    required={true}
                    type="text"
                    value={this.state.last_name}
                    className="form-control"
                    placeholder="Enter Last Name"
                    name="last_name"
                    onChange={(e) =>
                      this.setState({
                        last_name: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    E-mail <span style={{ color: "red" }}>(Required)</span>
                  </label>
                  <input
                    type="email"
                    required={true}
                    value={this.state.email}
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter E-mail"
                    name="email"
                    onChange={(e) =>
                      this.setState({
                        email: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    Mobile Number{" "}
                    <span style={{ color: "red" }}>(Required)</span>
                  </label>

                  <input
                    type="number"
                    required={true}
                    value={this.state.mobile_number}
                    className="form-control"
                    placeholder="Enter Mobile Number"
                    name="mobile_number"
                    onChange={(e) =>
                      this.setState({
                        mobile_number: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Delivery Number</label>
                  <input
                    type="number"
                    value={this.state.delivery_mobile}
                    className="form-control"
                    placeholder="Enter Delivery Number"
                    name="delivery_mobile"
                    onChange={(e) =>
                      this.setState({
                        delivery_mobile: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    Amount <span style={{ color: "red" }}>(Required)</span>
                  </label>

                  <input
                    type="number"
                    required={true}
                    min={1}
                    value={this.state.amount}
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Amount"
                    name="amount"
                    onChange={(e) =>
                      this.setState({
                        amount: String(e.target.value),
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <label>Notices</label>
              <textarea
                className="form-control"
                rows="5"
                id="comment"
                onChange={(e) => {
                  this.setState({
                    notices: e.target.value,
                  });
                }}
              ></textarea>
            </div> */}

            <Button
              bsStyle="info"
              pullRight
              fill
              onClick={() => {
                this.addTrans();
              }}
            >
              Add
            </Button>

            <div className="clearfix" />
          </form>
        }
      />
    );
  }
}

export default AddTrans;
