import { client } from "../apollo";
import { mutations } from "./mutations/index";

let UserHelper = {
  login: async userInfo => login(userInfo)
};

// login
async function login(userInfo) {
  let response = await client.mutate({
    mutation: mutations.LOGIN,
    variables: {
      email: String(userInfo.email),
      password: String(userInfo.password)
    }
  });
  let res = response.data;
  localStorage.setItem("token", res.loginMerchant.token);
  localStorage.setItem("user_first_name", res.loginMerchant.user.first_name);
  localStorage.setItem("user_id", res.loginMerchant.user.id);
  localStorage.setItem("user_last_name", res.loginMerchant.user.last_name);
  localStorage.setItem("user_email", res.loginMerchant.user.email);
  if (res.loginMerchant.user.subAccount === null) {
    localStorage.setItem("subAccount_id", "");
  } else {
    localStorage.setItem("subAccount_id", res.loginMerchant.user.subAccount.id);
  }
  return res;
}
// login

export default UserHelper;
