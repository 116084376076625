import React, { Component } from "react";
import { Grid, Row, Col, Tabs, Tab } from "react-bootstrap";
import AddMerchant from "components/Admin/AddMerchant";
import Merchants from "components/Admin/Merchants";
class Merchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_addFile: true
    };
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Tabs defaultActiveKey="user" id="uncontrolled-tab-example">
                <Tab eventKey="user" title="Merchants">
                  <br />
                  <Merchants />
                </Tab>
                <Tab eventKey="file" title="Add Merchant">
                  <br />
                  <AddMerchant />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Merchant;
