// for yousef soliman

//for localhost
// export const BASE_URL = "http://localhost:3000";
// export const NEXUS_URL = "http://localhost:4000";
//for localhost

// for live
export const BASE_URL = "https://app.onelink2pay.co";
export const NEXUS_URL = "https://back.onelink2pay.co";
// for live
