import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import TransHelper from "../Services/TransHelper";
import { BASE_URL } from "const";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "components/CustomButton/CustomButton.jsx";
const thArr = [
  "First Name",
  "Last Name",
  "Mobile Number",
  // "Mobile WhatsApp",
  "Amount",
  "E-mail",
  "SMS",
  // "WhatsApp",
  "Is Paid",
  "Pay Link",
  "Copy Link",
];
class Dashboard extends Component {
  state = {
    transactions: [],
  };
  componentDidMount() {
    TransHelper.getTrans().then((res) => {
      if (res.length > 0) {
        var trans = [];
        for (let i = 0; i < res.length; i++) {
          trans.push(res[i]);
        }

        this.setState({
          transactions: [...res],
        });
      }
    });
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-expand1 text-warning" />}
                statsText="Transactions"
                statsValue={this.state.transactions.length}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-mail text-success" />}
                statsText="Messages"
                statsValue={
                  this.state.transactions.filter(
                    (trans) => trans.is_smsSent === true
                  ).length
                }
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-mail text-danger" />}
                statsText="Messages"
                statsValue={
                  this.state.transactions.length -
                  this.state.transactions.filter(
                    (trans) => trans.is_smsSent === true
                  ).length
                }
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-check text-info" />}
                statsText="Paid"
                statsValue={
                  this.state.transactions.filter(
                    (trans) => trans.is_paid === true
                  ).length
                }
              />
            </Col>
          </Row>

          {this.state.transactions.length > 0 && (
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    plain
                    title="Payments Table"
                    // ctTableFullWidth
                    ctTableResponsive
                    content={
                      <div id="customContainer">
                        <Table>
                          <thead>
                            <tr>
                              {thArr.map((prop, key) => {
                                return <th key={key}>{prop}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.transactions.map((data, key) => {
                              return (
                                <tr key={data.id}>
                                  <td>{data.first_name}</td>
                                  <td>{data.last_name}</td>
                                  <td>{data.mobile_number}</td>
                                  {/* <td>{data.mobile_whatsApp}</td> */}
                                  <td>
                                    {" "}
                                    E£ {this.numberWithCommas(data.amount)}{" "}
                                  </td>
                                  <td>{data.email}</td>
                                  <td>
                                    {data.is_smsSent && (
                                      <i
                                        style={{
                                          fontSize: 20,
                                          padding: 0,
                                          marginTop: 10,
                                        }}
                                        className="fa fa-check-circle text-success"
                                      />
                                    )}
                                    {!data.is_smsSent && (
                                      <i
                                        style={{ fontSize: 20 }}
                                        className="fa fa-times-circle text-danger"
                                      />
                                    )}
                                  </td>
                                  {data.is_paid === null && (
                                    <td>
                                      <i
                                        style={{ fontSize: 20 }}
                                        className="fa fa-times-circle text-danger"
                                      />
                                    </td>
                                  )}
                                  {data.is_paid && (
                                    <td>
                                      <i
                                        style={{
                                          fontSize: 20,
                                          padding: 0,
                                          marginTop: 10,
                                        }}
                                        className="fa fa-check-circle text-success"
                                      />
                                    </td>
                                  )}
                                  <td>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${BASE_URL}/pay_trans/${data.id}`}
                                    >
                                      payment link
                                    </a>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <CopyToClipboard
                                      text={`${BASE_URL}/pay_trans/${data.id}`}
                                    >
                                      <Button bsStyle="success" pullRight fill>
                                        COPY
                                      </Button>
                                    </CopyToClipboard>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
