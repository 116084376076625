import React, { Component } from "react";
import CustomAlert from "components/CustomAlert/CustomAlert";
import SuperAdminHelper from "../../Services/SuperAdminHelper";
export class AdminLogin extends Component {
  state = {
    email: "",
    password: "",
    loginFailed: false
  };
  componentDidMount() {
    console.log(localStorage.getItem("admin_token"));
    if (localStorage.getItem("admin_token")) {
      this.props.history.push("/admin");
    }
  }
  async login() {
    await SuperAdminHelper.login({
      email: this.state.email,
      password: this.state.password
    })
      .then(res => {
        this.setState({ loginFailed: false });
        this.props.history.push("/bm_admin/merchant");
      })
      .catch(err => {
        this.setState({ loginFailed: true });
        setTimeout(() => {
          this.setState({ loginFailed: false });
        }, 3000);
      });
  }
  render() {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#f3f3f3",
            width: "360px",
            borderRadius: 20,
            padding: 50,
            paddingTop: 70
          }}
        >
          {this.state.loginFailed === true && (
            <CustomAlert
              title="Please"
              desc="Enter a vaild email and password"
              type="danger"
            />
          )}
          <h1 style={{ fontWeight: 600 }}>Admin Login</h1>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              name="email"
              onChange={e =>
                this.setState({
                  email: String(e.target.value)
                })
              }
            ></input>
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              name="password"
              onChange={e =>
                this.setState({
                  password: String(e.target.value)
                })
              }
            ></input>
          </div>
          <button className="btn btn-primary" onClick={() => this.login()}>
            Login
          </button>
        </div>
      </div>
    );
  }
}

export default AdminLogin;
