import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card";
import * as XLSX from "xlsx";
import UserTableCell from "components/UserTableCell/UserTableCell";
const thArr = [
  "First Name",
  "Last Name",
  "Mobile Number",
  "Mobile WhatsApp",
  "Amount",
  "E-mail",
  "SMS",
  "WhatsApp",
  "Is Expired",
  "Is Paid",
  "Pay Link",
  // "Pay Link (UPG)"
];
const thArrErr = [
  "First Name",
  "Last Name",
  "Mobile Number",
  "Mobile WhatsApp",
  "Amount",
  "E-mail",
];
export class AddTransFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sheet: [],
      errorSheet: [],
      errorRecords: 0,
      error: "",
      dbSheets: [],
      title: "",
    };
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  onChangeFile(e) {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsArrayBuffer(files[0]);
    reader.onload = (e) => {
      var data = e.target.result;
      data = new Uint8Array(data);
      console.log(data);
      this.setState({
        data,
      });
    };
  }
  handleFileData() {
    if (this.state.data != null) {
      let noOfErrors = 0;
      var workbook = XLSX.read(this.state.data, { type: "array" }); // parse the file
      var sheet = workbook.Sheets[workbook.SheetNames[0]]; // get the first worksheet
      console.log(sheet);
      if (
        sheet.A1.h == "first_name" &&
        sheet.B1.h == "last_name" &&
        sheet.C1.h == "mobile_number" &&
        sheet.D1.h == "mobile_whatsApp" &&
        sheet.E1.h == "amount" &&
        sheet.F1.h == "email"
      ) {
        console.log("SUCCESS");
        this.setState({ error: "" });
      } else {
        this.setState({ error: "This XLSX file is invaild" });
        return;
      }

      /* loop through every cell manually */
      var range = XLSX.utils.decode_range(sheet["!ref"]); // get the range
      var elements = [];
      var translatedElements = [];
      var errorElements = [];
      for (var R = range.s.r; R <= range.e.r; ++R) {
        var rows = [];
        let translatedRow = {};
        for (var C = range.s.c; C <= range.e.c; ++C) {
          /* find the cell object */
          var cellref = XLSX.utils.encode_cell({ c: C, r: R }); // construct A1 reference for cell
          if (!sheet[cellref]) continue; // if cell doesn't exist, move on
          var cell = sheet[cellref];
          rows.push(cell.v);
          if (elements[0]) {
            translatedRow[elements[0][C]] = cell.v;
          }
        }
        elements.push(rows);
        // translatedElements.push(translatedRow);
        console.log(translatedRow);
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = re.test(String(translatedRow.email).toLowerCase());
        const mobile_re = /^[002]\d{9}$/;
        if (
          translatedRow.first_name &&
          translatedRow.first_name.length > 0 &&
          translatedRow.last_name.length > 0 &&
          isValidEmail &&
          parseInt(translatedRow.amount) > 0
          // mobile_re.test(String(translatedRow.mobile_number))
        ) {
          console.log("SUCCESS ROW");
          translatedElements.push(translatedRow);
        } else {
          console.log("ERROR ROW", translatedRow);
          if (this.isEmpty(translatedRow)) {
            // Object is empty (Would return true in this example)
          } else {
            // Object is NOT empty
            errorElements.push(translatedRow);
          }
        }
      }
      this.setState({
        sheet: [...translatedElements],
        errorSheet: [...errorElements],
        title: "You Added " + this.state.sheet + " Payment",
      });
    }
  }

  render() {
    return (
      <Card
        title="Import File Of Transactions"
        content={
          <div>
            {this.state.sheet.length === 0 && (
              <form>
                <label>
                  Example for the XLSX file{" "}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1TI11yq9SmnFLvlR-Zg6eGXLfQ7623TpKUH_O650OYMk/edit#gid=0"
                    target="_blank"
                  >
                    DOWNLOAD HERE ...
                  </a>
                </label>
                <input
                  placeholder="File"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => this.onChangeFile(e)}
                />
                <Button
                  bsStyle="info"
                  onClick={() => this.handleFileData()}
                  pullRight
                  fill
                >
                  Upload
                </Button>

                <div className="clearfix" />
              </form>
            )}
            {this.state.error != "" && (
              <div>
                <h5 style={{ color: "red", fontWeight: 100 }}>
                  Worng file format please download example for correct format{" "}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1TI11yq9SmnFLvlR-Zg6eGXLfQ7623TpKUH_O650OYMk/edit#gid=0"
                    target="_blank"
                  >
                    DOWNLOAD HERE ...
                  </a>
                </h5>
              </div>
            )}
            {(this.state.sheet.length > 0 ||
              this.state.errorSheet.length > 0) && (
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    <Card
                      plain
                      title=""
                      // category="Here is a subtitle for this table"
                      // ctTableFullWidth
                      // ctTableResponsive
                      content={
                        <div>
                          {this.state.errorSheet.length > 0 && (
                            <div>
                              <h3 style={{ color: "red" }}>
                                Failed Transactions{" "}
                                {this.state.errorSheet.length}
                              </h3>
                              <div id="customContainer">
                                <Table>
                                  <thead>
                                    <tr>
                                      {thArrErr.map((prop, key) => {
                                        return <th key={key}>{prop}</th>;
                                      })}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.errorSheet.map((prop, key) => {
                                      return (
                                        <tr>
                                          <td>{prop.first_name}</td>
                                          <td>{prop.last_name}</td>
                                          <td>{prop.mobile_number}</td>
                                          <td>{prop.mobile_whatsApp}</td>
                                          <td>{prop.amount}</td>
                                          <td>{prop.email}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          )}

                          <div>
                            <h3 style={{ color: "green" }}>
                              Success Transactions {this.state.sheet.length}
                            </h3>
                            <Table hover>
                              <thead>
                                <tr>
                                  {thArr.map((prop, key) => {
                                    return <th key={key}>{prop}</th>;
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.sheet.map((prop, key) => {
                                  return (
                                    <UserTableCell cellKey={key} data={prop} />
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            )}
          </div>
        }
      />
    );
  }
}

export default AddTransFile;
