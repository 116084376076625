import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";
import Filters from "views/filters";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Add Payments",
    icon: "pe-7s-cash",
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Paid",
    icon: "pe-7s-check",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Unpaid",
    icon: "fa fa-times-circle",
    component: Typography,
    layout: "/admin",
  },
  // {
  //   path: "/filters",
  //   name: "Filters",
  //   icon: "fa fa-search",
  //   component: Filters,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
