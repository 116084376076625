import React, { Component } from "react";
import Misc from "../../Services/Misc.js";
import { BASE_URL } from "const.js";
export class UserTableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      gotoUrl: "/",
      gotoUrlUPG: "/",
      error: false,
      sendingSms: 0,
    };
  }
  componentDidMount() {
    let data = this.props.data;
    Misc.addUserToPrisma(data)
      .then((res) => {
        Misc.ceqAuth(res).then((res) => {
          this.setState({ sendingSms: 1 });
        });
        this.setState({
          isLoading: false,
          gotoUrl: `${BASE_URL}/pay_trans/${res.id}`,
          gotoUrlUPG: `${BASE_URL}/pay_upg/${res.id}`,
        });
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <tr>
        <td>{this.props.data.first_name}</td>
        <td>{this.props.data.last_name}</td>
        <td>{this.props.data.mobile_number}</td>
        <td>{this.props.data.mobile_whatsApp}</td>
        <td>{this.props.data.amount}</td>
        <td>{this.props.data.email}</td>
        <td>
          {this.state.sendingSms === 0 && <span>LOADING ...</span>}
          {this.state.sendingSms === 1 && <span>SENT</span>}
          {this.state.sendingSms === 2 && <span>ERROR</span>}
        </td>
        <td>NO</td>
        <td>NO</td>
        <td>NO</td>
        <td>
          {this.state.error && <span>Error!</span>}
          {this.state.isLoading ? (
            <span>loading</span>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.state.gotoUrl}
            >
              {this.state.gotoUrl}
            </a>
          )}
        </td>
        {/* <td>
          {this.state.error && <span>Error!</span>}
          {this.state.isLoading ? (
            <span>loading</span>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.state.gotoUrlUPG}
            >
              {this.state.gotoUrlUPG}
            </a>
          )}
        </td> */}
      </tr>
    );
  }
}

export default UserTableCell;
