import React, { Component } from "react";
import { client } from "../../apollo";

import axios from "axios";
import { queries } from "Services/queries";
import { mutations } from "Services/mutations";
import Misc from "../../Services/Misc.js";
let serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
export class Pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: "",
      amount: "",
      merchant_id: "",
      merchant_name: "",
      deliver_mobile: "",
      currency: "",
      username: "",
      password: "",
      successIndicator: null,
      is_paid: "",
      is_loading: true,
    };
  }
  componentDidMount() {
    let id = window.location.href.split("pay/")[1];
    this.getTransaction(id).then((res) => {
      console.log("DATA", res);
      this.setState({
        transaction_id: res.getTransaction.id,
        amount: res.getTransaction.amount,
        merchant_id: res.getTransaction.added_by.merchant.megs.merchantID,
        merchant_name: res.getTransaction.added_by.merchant.megs.merchantName,
        currency: res.getTransaction.added_by.merchant.megs.currency,
        username: res.getTransaction.added_by.merchant.megs.api_username,
        password: res.getTransaction.added_by.merchant.megs.api_password,
        sender_firstName: res.getTransaction.first_name,
        sender_lastName: res.getTransaction.last_name,
        is_paid: res.getTransaction.is_paid,
        paid_at: res.getTransaction.paid_at,
        delivery_mobile: res.getTransaction.delivery_mobile,
        is_loading: false,
      });
      let orderID = Math.floor(Math.random() * 100000000000000);
      this.createSession(res.getTransaction.amount, orderID);
      this.init_megs(res.getTransaction.amount, orderID);
    });
    this.setState({
      transaction_id: id,
    });

    setInterval(() => {
      this.onCompleteCheck();
    }, 100);
  }

  createSession(amount, orderId) {
    const info = {
      username: this.state.username,
      password: this.state.password,
      merchentId: this.state.merchant_id,
      orderId,
      amount,
    };
    const link = "http://healthpay.tech:4450/api/session/create";
    axios({
      method: "post",
      url: link,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        accept: "application/json",
      },
      data: serialize(info),
    })
      .then((res) => {
        this.setState({ session_id: res.data["session.id"] });
        this.setState({ successIndicator: res.data.successIndicator });
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  onCompleteCheck() {
    if (window.onCompleteNewValue === 1) {
      if (window.onCompleteSession) {
        window.onCompleteNewValue = 0;
        this.updateTransaction_isPaid(this.state.transaction_id)
          .then((res) => {
            console.log(res);
            let info = {
              first_name: this.state.sender_firstName,
              last_name: this.state.sender_lastName,
              delivery_mobile: this.state.delivery_mobile,
              // amount: userInfo.amount,
              // id: userInfo.id,
              // url: BASE_URL + "/pay/" + userInfo.id
            };

            Misc.sendSmsDelivery(info).then((res) => {
              console.log(res);
            });
            window.location.reload(true);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }
  async updateTransaction_isPaid(transaction_id) {
    let response = await client.mutate({
      mutation: mutations.UPDATE_TRANSACTION_ISPAID,
      variables: {
        transaction_id: transaction_id,
      },
    });
    return response.data;
  }
  async getTransaction(id) {
    let response = await client.query({
      query: queries.GET_TRANS,
      variables: {
        transaction_id: id,
      },
    });
    return response.data;
  }
  init_megs(amount, orderId) {
    window.transaction_id = this.state.transaction_id;
    window.DoCheckout({
      merchant: this.state.merchant_id,
      order: {
        amount: function () {
          //Dynamic calculation of amount
          return amount;
        },
        currency: "EGP",
        description: "Ordered goods",
        id: orderId,
      },
      interaction: {
        operation: "PURCHASE",
        merchant: {
          name: this.state.merchant_name,
        },
      },

      session: {
        id: this.state.session_id,
      },
    });
  }
  doPay() {
    window.showLightbox();
  }
  render() {
    return (
      <div>
        {/* {this.state.is_paid && !this.state.is_loading && (
          <div style={{ textAlign: "center" }}>
            <img
              src={paidBG}
              className="center"
              style={{
                flex: 1,
                height: window.innerHeight,
                opacity: 0.3
              }}
              className="imgBG"
            />
          </div>
        )} */}
        <div>
          {/* {!this.state.is_paid && !this.state.is_loading && (
            <div style={{ textAlign: "center" }}>
              <img
                src={bg}
                className="center smHidden"
                style={{
                  flex: 1,
                  height: window.innerHeight,
                  opacity: 0.3
                }}
                className="imgBG"
              />
            </div>
          )} */}

          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="content">
              {!this.state.is_paid && !this.state.is_loading && (
                <div
                  className="card"
                  style={{
                    padding: 50,
                    backgroundColor: "#f3f3f3",
                    borderRadius: 30,
                  }}
                >
                  <div>
                    <h1
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#17a2b8",
                      }}
                    >
                      ONE <span style={{ color: "#333333" }}>LINK</span>
                    </h1>
                    <hr style={{ backgroundColor: "#333333", height: 1 }} />
                    <h2 style={{ fontSize: 30, fontWeight: 600 }}>
                      PAY USING MASTERCARD/VISA CARDS
                    </h2>
                    <h3 style={{ fontSize: 20, fontWeight: 400 }}>
                      Welcome Mr {this.state.sender_firstName}{" "}
                      {this.state.sender_lastName}{" "}
                    </h3>
                    <h4 className="title" style={{ fontSize: 18 }}>
                      Please Pay Amount{" "}
                      <span>
                        {" "}
                        {this.state.amount} {this.state.currency}
                      </span>
                    </h4>
                    <h4 className="title" style={{ fontSize: 18 }}>
                      Paid to : <span> {this.state.merchant_name}</span>
                    </h4>
                    <p className="category">
                      {/* Hello Mr : {this.state.sender_firstName}{" "}
                    {this.state.sender_lastName} */}
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.doPay()}
                      style={{ marginTop: 15 }}
                    >
                      Pay
                    </button>
                  </div>
                </div>
              )}
              {this.state.is_paid && !this.state.is_loading && (
                <div
                  className="card"
                  style={{
                    padding: 50,
                    borderRadius: 30,
                    background: "#434343",
                  }}
                >
                  <h1 style={{ fontSize: 30, fontWeight: 600, color: "#fff" }}>
                    Welcome Mr {this.state.sender_firstName}{" "}
                    {this.state.sender_lastName}{" "}
                  </h1>
                  <i
                    className="fa fa-check-circle"
                    style={{
                      fontSize: 30,
                      color: "white",
                    }}
                  />
                  <span
                    style={{
                      paddingLeft: 10,
                      fontSize: 20,
                      color: "white",
                    }}
                  >
                    {" "}
                    This Payment Paid Successfully{" "}
                  </span>
                  <hr style={{ backgroundColor: "white", padding: 1 }} />
                  <p style={{ color: "white" }}>
                    <i className="fa fa-calendar"></i> Paid at :{" "}
                    {this.state.paid_at}
                  </p>
                  <p style={{ color: "white" }}>
                    <i className="fa fa-credit-card"></i> Paid to :{" "}
                    {this.state.merchant_name}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pay;
