import React, { Component } from "react";
import UserHelper from "../../Services/UserHelper";
import CustomAlert from "components/CustomAlert/CustomAlert";
import bg from "../../assets/img/app-bg.svg";
import CustomButton from "components/CustomButton/CustomButton";

export class Login extends Component {
  state = {
    email: "",
    password: "",
    loginFailed: false,
  };
  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push("/admin");
    }
  }
  async login() {
    await UserHelper.login({
      email: this.state.email,
      password: this.state.password,
    })
      .then((res) => {
        this.setState({ loginFailed: false });
        this.props.history.push("/admin/dashboard");
      })
      .catch((err) => {
        this.setState({ loginFailed: true });
        setTimeout(() => {
          this.setState({ loginFailed: false });
        }, 3000);
      });
  }

  render() {
    return (
      <div>
        <img src={bg} className="imgBG" />
        <div
          style={{
            position: "absolute",

            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            className="loginContainer"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundColor: "#f3f3f3",
              border: "1px solid #f3f3f3",
              borderRadius: 20,
              padding: 50,
              width: "360px",
            }}
          >
            {this.state.loginFailed === true && (
              <CustomAlert
                title="Please"
                desc="Enter a vaild email and password"
                type="danger"
              />
            )}
            <h1
              style={{
                textAlign: "center",
                fontWeight: 600,
                color: "#17a2b8",
              }}
            >
              ONE <span style={{ color: "#223764" }}>LINK</span>
            </h1>
            <hr style={{ backgroundColor: "#333333", height: 1 }} />
            <h1 style={{ fontWeight: 600 }}>Login</h1>
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                name="email"
                onChange={(e) =>
                  this.setState({
                    email: String(e.target.value),
                  })
                }
              ></input>
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                name="password"
                onChange={(e) =>
                  this.setState({
                    password: String(e.target.value),
                  })
                }
              ></input>
            </div>

            <div style={{ textAlign: "center" }}>
              <CustomButton fill onClick={() => this.login()}>
                Login
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
