import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import Misc from "Services/Misc";

const thArr = [
  "First Name",
  "Last Name",
  "E-mail",
  "Merchant Name",
  "Merchant Email",
  "MEGS ID",
  "UPG ID"
];
class AdminUsers extends Component {
  state = {
    users: []
  };
  componentDidMount() {
    Misc.getUsers().then(res => {
      console.log(res);
      var trans = [];
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          trans.push(res[i]);
        }
        this.setState({
          users: [...res]
        });
      }
    });
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          {this.state.users.length > 0 && (
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    plain
                    title="users Table"
                    // category="Here is a subtitle for this table"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <Table hover>
                        <thead>
                          <tr>
                            {thArr.map((prop, key) => {
                              return <th key={key}>{prop}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.users.map((data, key) => {
                            return (
                              <tr>
                                <td>{data.first_name}</td>
                                <td>{data.last_name}</td>
                                <td>{data.email}</td>
                                <td>{data.merchant.name}</td>
                                <td>{data.merchant.email}</td>
                                <td>{data.merchant.megs.merchantID}</td>
                                {data.merchant.upg && (
                                  <td>{data.merchant.upg.mID}</td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          )}

          {/* <Row>
            <Col md={8}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Users Behavior"
                category="24 Hours performance"
                stats="Updated 3 minutes ago"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataSales}
                      type="Line"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendSales)}</div>
                }
              />
            </Col>
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Email Statistics"
                category="Last Campaign Performance"
                stats="Campaign sent 2 days ago"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={dataPie} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendPie)}</div>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card
                id="chartActivity"
                title="2014 Sales"
                category="All products including Taxes"
                stats="Data information certified"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendBar)}</div>
                }
              />
            </Col>

            <Col md={6}>
              <Card
                title="Tasks"
                category="Backend development"
                stats="Updated 3 minutes ago"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                      <Tasks />
                    </table>
                  </div>
                }
              />
            </Col>
          </Row> */}
        </Grid>
      </div>
    );
  }
}

export default AdminUsers;
