import { gql } from "apollo-boost";
const ADD_TRANSACTION = gql`
  mutation addTransAction(
    $first_name: String!
    $last_name: String!
    $email: String!
    $amount: Float!
    $mobile_number: String!
    $mobile_whatsApp: String!
    $delivery_mobile: String
  ) {
    createTransaction(
      first_name: $first_name
      last_name: $last_name
      email: $email
      amount: $amount
      mobile_number: $mobile_number
      mobile_whatsApp: $mobile_whatsApp
      delivery_mobile: $delivery_mobile
    ) {
      id
      first_name
      last_name
      email
      amount
      delivery_mobile
      mobile_number
      mobile_whatsApp
    }
  }
`;

const UPDATE_TRANSACTION_SMS = gql`
  mutation updateTransActionSms($transaction_id: ID!) {
    updateTransaction_Sms(transaction_id: $transaction_id) {
      id
      is_smsSent
    }
  }
`;

const UPDATE_TRANSACTION_ISPAID = gql`
  mutation updateTransActionIsPaid($transaction_id: ID!) {
    updateTransaction_isPaid(transaction_id: $transaction_id) {
      id
      is_paid
      delivery_mobile
      paid_at
      updated_at
    }
  }
`;

const CREATE_MERCHANT = gql`
  mutation createMerchant(
    $name: String!
    $email: String!
    $currency: String
    $megs_merchantID: String!
    $megs_merchantName: String!
    $megs_apiUsername: String!
    $megs_apiPassword: String!
    $megs_returnUrl: String!
    $upg_mID: String!
    $upg_tID: String!
    $upg_secureHash: String!
    $upg_returnUrl: String!
    $address_line1: String!
    $address_line2: String!
    $mobile_number: String!
  ) {
    createMerchant(
      name: $name
      email: $email
      currency: $currency
      megs_merchantID: $megs_merchantID
      megs_merchantName: $megs_merchantName
      megs_apiUsername: $megs_apiUsername
      megs_apiPassword: $megs_apiPassword
      megs_returnUrl: $megs_returnUrl
      upg_mID: $upg_mID
      upg_tID: $upg_tID
      upg_secureHash: $upg_secureHash
      upg_returnUrl: $upg_returnUrl
      address_line1: $address_line1
      address_line2: $address_line2
      mobile_number: $mobile_number
    ) {
      id
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $first_name: String!
    $last_name: String!
    $password: String!
    $merchant: ID!
    $mobile_number: String!
  ) {
    createUser(
      email: $email
      first_name: $first_name
      last_name: $last_name
      password: $password
      merchant: $merchant
      mobile_number: $mobile_number
    ) {
      user {
        id
        first_name
        last_name
        email
        mobile_number
        merchant {
          id
        }
      }
    }
  }
`;

const LOGIN = gql`
  mutation loginMerchant($email: String!, $password: String!) {
    loginMerchant(email: $email, password: $password) {
      token
      user {
        id
        first_name
        last_name
        email
        merchant {
          id
          name
        }
        subAccount {
          id
        }
      }
    }
  }
`;

const ADMIN_LOGIN = gql`
  mutation loginUserAdmin($email: String!, $password: String!) {
    loginUserAdmin(email: $email, password: $password) {
      token
      admin {
        id
        first_name
        last_name
        email
      }
    }
  }
`;
export const mutations = {
  ADD_TRANSACTION,
  UPDATE_TRANSACTION_SMS,
  UPDATE_TRANSACTION_ISPAID,
  CREATE_MERCHANT,
  CREATE_USER,
  LOGIN,
  ADMIN_LOGIN,
};
