import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Misc from "Services/Misc";

const thArr = [
  "Name",
  "Email",
  "Mobile Number",
  "Megs Merchant ID",
  "Megs Merchant Name",
  "Megs Api Username",
  "Megs Api Password",
  "UPG MID",
  "UPG TID",
  "UPG Secure Hash"
];
class Merchants extends Component {
  state = {
    merchants: []
  };
  componentDidMount() {
    Misc.getMerchants().then(res => {
      console.log(res);
      var trans = [];
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          trans.push(res[i]);
        }
        this.setState({
          merchants: [...res]
        });
      }
    });
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          {this.state.merchants.length > 0 && (
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <Card
                    plain
                    title="Merchants Table"
                    // category="Here is a subtitle for this table"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <Table hover>
                        <thead>
                          <tr>
                            {thArr.map((prop, key) => {
                              return <th key={key}>{prop}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.merchants.map((data, key) => {
                            return (
                              <tr key={data.id}>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.mobile_number}</td>
                                <td>{data.megs.merchantID}</td>
                                <td>{data.megs.merchantName}</td>
                                <td>{data.megs.api_username}</td>
                                <td>{data.megs.api_password}</td>
                                {data.upg && <td>{data.upg.mID}</td>}
                                {data.upg && <td>{data.upg.tID}</td>}
                                {data.upg && <td>{data.upg.secureHash}</td>}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default Merchants;
