import { client } from "../apollo";
import { mutations } from "./mutations/index";

let SuperAdminHelper = {
  login: async userInfo => login(userInfo)
};

// login
async function login(userInfo) {
  let response = await client.mutate({
    mutation: mutations.ADMIN_LOGIN,
    variables: {
      email: String(userInfo.email),
      password: String(userInfo.password)
    }
  });
  let res = response.data;
  localStorage.setItem("admin_token", res.loginUserAdmin.token);
  localStorage.setItem("admin_first_name", res.loginUserAdmin.admin.first_name);
  localStorage.setItem("adminUser_id", res.loginUserAdmin.admin.id);
  localStorage.setItem("admin_last_name", res.loginUserAdmin.admin.last_name);
  localStorage.setItem("admin_email", res.loginUserAdmin.admin.email);
  return res;
}
// login

export default SuperAdminHelper;
