import Maps from "views/Maps.jsx";
import Notifications from "views/Notifications.jsx";

const AdminRoutes = [
  {
    path: "/merchant",
    name: "Merchants",
    icon: "pe-7s-science",
    component: Maps,
    layout: "/bm_admin"
  },
  {
    path: "/notifications",
    name: "Users",
    icon: "fa fa-users",
    component: Notifications,
    layout: "/bm_admin"
  }
];

export default AdminRoutes;
