import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./apollo";
import Main from "components/Main/Main";

setInterval(() => {
  if (
    window.onCompleteData &&
    window.transaction_id &&
    window.onCompleteNewValue === 1
  ) {
    console.log(window.onCompleteData);
  }
}, 1000);

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Switch>
        <Main />
        {/* <Login /> */}
        {/* <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/dashboard" /> */}
      </Switch>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);
