import axios from "axios";
import { client } from "../apollo";
import { mutations } from "../Services/mutations/index";
import { queries } from "../Services/queries/index";
import { BASE_URL } from "const";

let update_sms = {
  update: async (transaction_id) => {
    let response = await client.mutate({
      mutation: mutations.UPDATE_TRANSACTION_SMS,
      variables: {
        transaction_id: transaction_id,
      },
    });
    return response.data;
  },
};
let sendSms = {
  send: async (token, userInfo) => {
    let info = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      phone: "002" + userInfo.mobile_number,
      amount: userInfo.amount,
      id: userInfo.id,
      url: BASE_URL + "/pay_trans/" + userInfo.id,
    };

    const link =
      "https://api.cequens.com/cequens/api/v1/messaging?access_token=" + token;
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      messageText: "Please pay using " + info.url,
      senderName: "HealthPay",
      messageType: "text",
      recipients: info.phone,
    };
    console.log(data);
    axios
      .post(link, data, { headers: headers })
      .then((res) => {
        return update_sms.update(userInfo.id).then((res) => {
          console.log(res);
        });
      })
      .catch((e) => {
        console.warn("Message Not Sent");
      });
  },
};

let doSendDeliverySms = {
  send: async (token, userInfo) => {
    let info = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      phone: "002" + userInfo.delivery_mobile,
      // amount: userInfo.amount,
      // id: userInfo.id,
      // url: BASE_URL + "/pay/" + userInfo.id
    };

    const link =
      "https://api.cequens.com/cequens/api/v1/messaging?access_token=" + token;
    const headers = {
      "Content-Type": "application/json",
    };
    let msg =
      info.first_name +
      " " +
      info.last_name +
      " has been paid his order successfully";
    const data = {
      messageText: msg,
      senderName: "HealthPay",
      messageType: "text",
      recipients: info.phone,
    };
    console.log(data);
    axios
      .post(link, data, { headers: headers })
      .then((res) => {
        return update_sms.update(userInfo.id).then((res) => {
          console.log(res);
        });
      })
      .catch((e) => {
        console.warn("Message Not Sent");
      });
  },
};

let misc = {
  sendSmsDelivery: async (info) => {
    const authData = {
      apiKey: "ce89a71a-aa02-4f3f-b775-9d4458f28678",
      userName: "HAG",
    };
    const link = "https://api.cequens.com/cequens/api/v1/signin";
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(link, authData, { headers: headers })
      .then((res) => {
        doSendDeliverySms.send(res.data.data.access_token, info);
      })
      .catch((e) => console.log(e));
  },
  getUsers: async () => {
    let response = await client.query({
      query: queries.GET_USERS,
      variables: {},
    });
    return response.data.allUsers;
  },
  getMerchants: async () => {
    let response = await client.query({
      query: queries.GET_MERCHANTS,
      variables: {},
    });
    return response.data.merchants;
  },
  createUser: async (data) => {
    if (data) {
      let response = await client.mutate({
        mutation: mutations.CREATE_USER,
        variables: data,
      });
      return response.data.createUser;
    }
  },
  createMerchant: async (data) => {
    if (data) {
      let response = await client.mutate({
        mutation: mutations.CREATE_MERCHANT,
        variables: {
          name: data.name,
          email: data.email,
          currency: data.currency,
          megs_merchantID: data.megs_merchantID,
          megs_merchantName: data.megs_merchantName,
          megs_apiUsername: data.megs_apiUsername,
          megs_apiPassword: data.megs_apiPassword,
          megs_returnUrl: data.megs_returnUrl,
          upg_mID: data.upg_mID,
          upg_tID: data.upg_tID,
          upg_secureHash: data.upg_secureHash,
          upg_returnUrl: data.upg_returnUrl,
          address_line1: data.address_line1,
          address_line2: data.address_line2,
          mobile_number: data.mobile_number,
        },
      });
      return response.data.createMerchant;
    }
  },
  create_session: async (merchant_id) => {
    const link =
      "https://banquemisr.gateway.mastercard.com/api/rest/version/53/merchant/TESTCLOUD_X9_EGP/session";
    const headers = {
      Authorization:
        "Basic bWVyY2hhbnQuVEVTVENMT1VEX1g5X0VHUDo0N2UyOWM0MDc1M2U1ODVkMGZjN2UzODY1MmJkMDBjMg==",
    };
    axios
      .post(link, { headers: headers })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  },
  addUserToPrisma: async (user) => {
    if (user) {
      let response = await client.mutate({
        mutation: mutations.ADD_TRANSACTION,
        variables: {
          delivery_mobile: user.delivery_mobile,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          amount: user.amount,
          mobile_number: String(user.mobile_number),
          mobile_whatsApp: String(user.mobile_whatsApp),
        },
      });
      return response.data.createTransaction;
    }
  },
  getMerchantTransactions: async () => {
    let response = await client.query({
      query: queries.MERCHANT_TRANSACTIONS,
      variables: {},
    });
    return response.data.userTransactions;
  },
  ceqAuth: async (info) => {
    const authData = {
      apiKey: "ce89a71a-aa02-4f3f-b775-9d4458f28678",
      userName: "HAG",
    };
    const link = "https://api.cequens.com/cequens/api/v1/signin";
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(link, authData, { headers: headers })
      .then((res) => {
        sendSms.send(res.data.data.access_token, info);
      })
      .catch((e) => console.log(e));
  },
};

export default misc;
