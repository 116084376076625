import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Misc from "../Services/Misc";
import { BASE_URL } from "const";
import Button from "components/CustomButton/CustomButton.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomRadio from "components/CustomRadio/CustomRadio";
import moment from "moment";
const thArr = [
  "First Name",
  "Last Name",
  "Mobile Number",
  // "Mobile WhatsApp",
  "Amount",
  "E-mail",
  "SMS",
  // "WhatsApp",
  "Is Paid",
  "Pay Link",
  "Copy Link",
];
class Filters extends Component {
  state = {
    transactions: [],
    filtered_transactions: [],
    start_date: "",
    end_date: "",
    err: "",
    selectedValue: "",
  };
  componentDidMount() {
    Misc.getMerchantTransactions().then((res) => {
      var trans = [];
      for (let i = 0; i < res.length; i++) {
        trans.push(res[i]);
      }
      this.setState({
        transactions: [
          ...res.filter(
            (data) => data.is_paid === false || data.is_paid === null
          ),
        ],
      });
    });
  }
  search() {
    if (this.state.start_date == "" || this.setState.end_date == "") {
      this.setState({ err: "Please Select The Start And End Date" });
    } else {
      this.setState({ err: "" });
      let arr = this.state.transactions;
      let filteredArr = arr.filter(
        (data) => data.created_at === false || data.is_paid === null
      );
    }
  }
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      ...this.state,
      selectedValue: event.target.value,
    });
  };
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Filter Payments"
                category=""
                content={
                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Start Date</label>
                          <input
                            type="date"
                            value={this.state.first_name}
                            className="form-control"
                            placeholder="Choose Start Date"
                            name="start_date"
                            onChange={(e) => {
                              console.log(String(e.target.value));
                              this.setState({
                                start_date: String(e.target.value),
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>End Date</label>
                          <input
                            type="date"
                            value={this.state.first_name}
                            className="form-control"
                            placeholder="Choose End Date"
                            name="end_date"
                            onChange={(e) =>
                              this.setState({
                                end_date: String(e.target.value),
                              })
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Please Select Payment Type</label>
                          <CustomRadio
                            number="1"
                            label="PAID"
                            option="paid"
                            name="radio"
                            // onChange={this.handleChange}
                          />
                          <CustomRadio
                            number="2"
                            label="UNPAID"
                            option="unpaid"
                            name="radio"
                            // onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Button
                            bsStyle="info"
                            onClick={() => {
                              this.search();
                            }}
                            pullRight
                            fill
                          >
                            Search
                          </Button>
                        </div>
                      </div>
                    </div>
                    {this.state.err != "" && <div>{this.state.err}</div>}
                    <Table hover>
                      <thead>
                        <tr>
                          {thArr.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.filtered_transactions.map((data, key) => {
                          return (
                            <tr key={data.id}>
                              <td>{data.first_name}</td>
                              <td>{data.last_name}</td>
                              <td>{data.mobile_number}</td>
                              {/* <td>{data.mobile_whatsApp}</td> */}
                              <td>{data.amount} EGP</td>
                              <td>{data.email}</td>
                              {/* <td>Sent</td> */}
                              <td>
                                {data.is_smsSent && (
                                  <i
                                    style={{
                                      fontSize: 20,
                                      padding: 0,
                                      marginTop: 10,
                                    }}
                                    className="fa fa-check-circle text-success"
                                  />
                                )}
                                {!data.is_smsSent && (
                                  <i
                                    style={{ fontSize: 20 }}
                                    className="fa fa-times-circle text-danger"
                                  />
                                )}
                              </td>
                              <td>
                                <i
                                  style={{ fontSize: 20 }}
                                  className="fa fa-times-circle text-danger"
                                />
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${BASE_URL}/pay_trans/${data.id}`}
                                >
                                  payment link
                                </a>
                              </td>
                              <td>
                                <CopyToClipboard
                                  text={`${BASE_URL}/pay_trans/${data.id}`}
                                >
                                  <Button bsStyle="success" pullRight fill>
                                    COPY
                                  </Button>
                                </CopyToClipboard>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Filters;
