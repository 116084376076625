import { client } from "../apollo";
import { queries } from "./queries/index";
import { mutations } from "./mutations/index";
let TransHelper = {
  getTrans: async () => allTrans(),
  createTrans: async (transInfo) => createTrans(transInfo),
  updateTransSms: async (transID) => updateTransSms(transID),
};

// create merchant transaction
async function createTrans(transInfo) {
  let response = await client.mutate({
    mutation: mutations.ADD_TRANSACTION,
    variables: {
      delivery_mobile: transInfo.delivery_mobile,
      first_name: transInfo.first_name,
      last_name: transInfo.last_name,
      email: transInfo.email,
      amount: transInfo.amount,
      mobile_number: String(transInfo.mobile_number),
      mobile_whatsApp: String(transInfo.mobile_whatsApp),
    },
  });
  return response.data.createTransaction;
}
// create merchant transaction

// update transaction sms
async function updateTransSms(transID) {
  let response = await client.mutate({
    mutation: mutations.UPDATE_TRANSACTION_SMS,
    variables: {
      transaction_id: transID,
    },
  });
  return response.data;
}
// update transaction sms

// get all merchant transactions
async function allTrans() {
  let response = await client.query({
    query: queries.MERCHANT_TRANSACTIONS,
    variables: {},
  });
  return response.data.userTransactions;
}
// get all merchant transactions

export default TransHelper;
