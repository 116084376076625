import axios from "axios";

let SmsHelper = {
  sendSms: async (smsInfo) => sendSms(smsInfo),
};

// send sms
async function sendSms(smsInfo) {
  const authData = {
    apiKey: "ce89a71a-aa02-4f3f-b775-9d4458f28678",
    userName: "HAG",
  };
  const link = "https://api.cequens.com/cequens/api/v1/signin";
  const headers = {
    "Content-Type": "application/json",
  };
  axios
    .post(link, authData, { headers: headers })
    .then((res) => {
      const token = res.data.data.access_token;
      const link =
        "https://api.cequens.com/cequens/api/v1/messaging?access_token=" +
        token;
      const headers = {
        "Content-Type": "application/json",
      };
      console.log("SMS PHONE NUMBER IS" + smsInfo.mobile_number);
      const data = {
        messageText: smsInfo.txt,
        senderName: "HealthPay",
        messageType: "text",
        recipients: smsInfo.mobile_number,
      };
      axios
        .post(link, data, { headers: headers })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          console.warn("Message Not Sent");
        });
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
}
// send sms

export default SmsHelper;
