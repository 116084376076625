import React, { Component } from "react";
import { client } from "../../apollo";

import axios from "axios";
import { queries } from "Services/queries";
import { mutations } from "Services/mutations";
import Misc from "../../Services/Misc.js";
import bg from "../../assets/img/app-bg.svg";
import mobileBG from "../../assets/img/mobileBG.svg";
import appImg from "../../assets/img/app-img.svg";
let serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: "",
      amount: "",
      merchant_id: "",
      merchant_name: "",
      deliver_mobile: "",
      currency: "",
      username: "",
      password: "",
      successIndicator: null,
      is_paid: "",
      paid_at: "",
      is_loading: true,
      size: 0,
      selectedValue: "",
      selectedValueErr: false,
      isCheckbox: false,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    let id = window.location.href.split("terms/")[1];
    this.getTransaction(id).then((res) => {
      console.log("DATA", res);
      this.setState({
        transaction_id: res.getTransaction.id,
        amount: res.getTransaction.amount,
        merchant_id: res.getTransaction.added_by.merchant.megs.merchantID,
        merchant_name: res.getTransaction.added_by.merchant.megs.merchantName,
        currency: res.getTransaction.added_by.merchant.megs.currency,
        username: res.getTransaction.added_by.merchant.megs.api_username,
        password: res.getTransaction.added_by.merchant.megs.api_password,
        sender_firstName: res.getTransaction.first_name,
        sender_lastName: res.getTransaction.last_name,
        is_paid: res.getTransaction.is_paid,
        paid_at: res.getTransaction.paid_at,
        delivery_mobile: res.getTransaction.delivery_mobile,
        is_loading: false,
      });
    });
    this.setState({
      transaction_id: id,
    });
  }
  resize() {
    console.log(window.innerWidth);
    this.setState({ size: window.innerWidth });
  }
  async getTransaction(id) {
    let response = await client.query({
      query: queries.GET_TRANS,
      variables: {
        transaction_id: id,
      },
    });
    return response.data;
  }

  render() {
    return (
      <div>
        {this.state.size >= 1023 && (
          <img
            src={bg}
            className="center"
            style={{
              flex: 1,
              opacity: 0.2,
            }}
            className="imgBG"
          />
        )}
        {this.state.size < 1023 && (
          <img
            src={mobileBG}
            className="center"
            style={{
              flex: 1,
              opacity: 0.2,
            }}
            className="imgBG"
          />
        )}
        <div>
          <div
            style={{
              position: "absolute",
              left: "0%",
              top: "0%",
              width: this.state.size,
            }}
          >
            <div className="container">
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <span style={{ color: "#00B7D0", size: 18, fontWeight: 900 }}>
                  One
                </span>
                <span style={{ color: "#005662", size: 18, fontWeight: 900 }}>
                  Link
                </span>
              </div>
              {/* web ui */}

              {this.state.size >= 1024 && (
                <div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              )}
              {/* web ui */}

              {/* mobile ui */}
              {this.state.size < 1024 && (
                <div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              )}
              {/* mobile ui */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
