import React, { Component } from "react";
import { client } from "../../apollo";

import axios from "axios";
import { queries } from "Services/queries";
import { mutations } from "Services/mutations";
import Misc from "../../Services/Misc.js";
import bg from "../../assets/img/app-bg.svg";
import mobileBG from "../../assets/img/mobileBG.svg";
import appImg from "../../assets/img/app-img.svg";
import dateImg from "../../assets/img/date_ic.svg";
import timeImg from "../../assets/img/time_ic.svg";
import masterImg from "../../assets/img/master-ic.svg";
import visaImg from "../../assets/img/visa-ic.svg";
import meezImg from "../../assets/img/meeza-ic.svg";
import orangeImg from "../../assets/img/orange-ic.svg";
import vodavoneImg from "../../assets/img/vodavone-ic.svg";
import etisalatImg from "../../assets/img/etisalat-ic.svg";
import successPayment from "../../assets/img/success_payment.svg";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import CustomButton from "components/CustomButton/CustomButton";
import LoadingOverlay from "react-loading-overlay";
import SmsHelper from "../../Services/SmsHelper";
import moment from "moment";
let serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export class PayTrans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: "",
      amount: "",
      merchant_id: "",
      merchant_name: "",
      deliver_mobile: "",
      currency: "",
      username: "",
      password: "",
      successIndicator: null,
      is_paid: "",
      paid_at: "",
      is_loading: true,
      size: 0,
      selectedValue: "",
      selectedValueErr: false,
      isCheckbox: false,
      checkBoxErr: "",
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    let id = window.location.href.split("pay_trans/")[1];
    this.getTransaction(id).then((res) => {
      console.log("DATA", res);
      this.setState({
        transaction_id: res.getTransaction.id,
        amount: res.getTransaction.amount,
        merchant_id: res.getTransaction.added_by.merchant.megs.merchantID,
        merchant_name: res.getTransaction.added_by.merchant.megs.merchantName,
        currency: res.getTransaction.added_by.merchant.megs.currency,
        username: res.getTransaction.added_by.merchant.megs.api_username,
        password: res.getTransaction.added_by.merchant.megs.api_password,
        sender_firstName: res.getTransaction.first_name,
        sender_lastName: res.getTransaction.last_name,
        is_paid: res.getTransaction.is_paid,
        paid_at: res.getTransaction.paid_at,
        delivery_mobile: res.getTransaction.delivery_mobile,
        returnUrl: res.getTransaction.added_by.merchant.megs.returnUrl,
        is_loading: false,
      });
      let orderID = Math.floor(Math.random() * 100000000000000);
      this.createSession(res.getTransaction.amount, orderID);
      this.init_megs(res.getTransaction.amount, orderID);
      this.init_upg(res.getTransaction.amount, orderID);
    });
    this.setState({
      transaction_id: id,
    });

    setInterval(() => {
      this.onCompleteCheck();
      this.onCancel();
    }, 100);
  }
  resize() {
    console.log(window.innerWidth);
    this.setState({ size: window.innerWidth });
  }
  handleCheckbox = (event) => {
    const target = event.target;
    console.log(event.target);
    //  this.setState({
    //    [target.name]: target.checked,
    //  });
  };
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      ...this.state,
      selectedValue: event.target.value,
      selectedValueErr: false,
    });
  };
  createSession(amount, orderId) {
    const info = {
      username: this.state.username,
      password: this.state.password,
      merchentId: this.state.merchant_id,
      orderId,
      amount,
    };
    const link = "http://onelink2pay.co:4450/api/session/create";
    axios({
      method: "post",
      url: link,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        accept: "application/json",
      },
      data: serialize(info),
    })
      .then((res) => {
        this.setState({ session_id: res.data["session.id"] });
        this.setState({ successIndicator: res.data.successIndicator });
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }
  onCancel() {
    if (window.onCancel === 1) {
      this.setState({ is_loading: false });
      window.onCancel = 0;
    } else {
    }
  }
  onCompleteCheck() {
    if (window.onCompleteNewValue === 1) {
      if (window.onCompleteSession) {
        window.onCompleteNewValue = 0;
        this.setState({ is_loading: false });
        this.updateTransaction_isPaid(this.state.transaction_id)
          .then((res) => {
            console.log(res);
            let mobile_number = this.state.delivery_mobile;
            let name = `${this.state.sender_firstName} ${this.state.sender_lastName}`;
            let txt = `Mr/Mrs ${name} paid amount ${this.state.amount} EGP to ${this.state.merchant_name}`;
            console.log(mobile_number, name, txt);
            let returnUrl = this.state.returnUrl;
            SmsHelper.sendSms({
              txt: txt,
              mobile_number: "002" + mobile_number,
            })
              .then((res) => {
                console.log(res);
                setTimeout(function () {
                  window.location.href = returnUrl;
                }, 2000);
              })
              .catch((err) => {
                setTimeout(function () {
                  window.location.href = returnUrl;
                }, 2000);
                console.log(err);
              });

            // window.location.reload(true);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.setState({ is_loading: false });
      }
    }
  }

  async updateTransaction_isPaid(transaction_id) {
    let response = await client.mutate({
      mutation: mutations.UPDATE_TRANSACTION_ISPAID,
      variables: {
        transaction_id: transaction_id,
      },
    });
    return response.data;
  }
  async getTransaction(id) {
    let response = await client.query({
      query: queries.GET_TRANS,
      variables: {
        transaction_id: id,
      },
    });
    return response.data;
  }
  init_upg(amount, orderId) {
    console.log(amount);
    window.transaction_id = this.state.transaction_id;
    window.DoCheckoutUPG({
      OrderId: "",
      paymentMethodFromLightBox: null,
      MID: this.state.upg_mID,
      TID: this.state.upg_tID,
      SecureHash: this.state.upg_secureHash,
      TrxDateTime: String(Date.now()),
      AmountTrxn: amount * 100,
      MerchantReference: "",
      ReturnUrl: "",
      completeCallback: function (data) {
        //your code here
        this.updateTransaction_isPaid(this.state.transaction_id)
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      errorCallback: function () {
        //your code here
        console.log("error in filled data");
      },
      cancelCallback: function () {
        //your code here
        console.log("cancelled");
      },
    });
  }
  init_megs(amount, orderId) {
    window.transaction_id = this.state.transaction_id;
    window.DoCheckout({
      merchant: this.state.merchant_id,
      order: {
        amount: function () {
          //Dynamic calculation of amount
          return amount;
        },
        currency: "EGP",
        description: "Ordered goods",
        id: orderId,
      },
      interaction: {
        operation: "PURCHASE",
        displayControl: {
          orderSummary: "SHOW",
          paymentConfirmation: "HIDE",
          billingAddress: "HIDE",
          customerEmail: "HIDE",
          shipping: "HIDE",
        },
        merchant: {
          name: this.state.merchant_name,
        },
      },
      session: {
        id: this.state.session_id,
      },
    });
  }
  pay() {
    console.log("PAY BTN CLICKED");

    if (this.state.isCheckbox == false) {
      this.setState({ checkBoxErr: "please select the terms and conditions" });
      return;
    } else {
      this.setState({ checkBoxErr: "" });
    }
    if (this.state.selectedValue == "") {
      this.setState({ selectedValueErr: true });
    }
    if (this.state.selectedValue == "upg") {
      this.setState({ is_loading: true });
      this.doPayUPG();
    } else {
      this.setState({ is_loading: true });
      this.doPay();
    }
  }
  doPay() {
    window.showLightbox();
  }
  doPayUPG() {
    window.showLightBoxUPG();
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.state.is_loading}
          spinner
          text="Loading..."
        >
          {this.state.size >= 1023 && (
            <img
              src={bg}
              className="center"
              style={{
                flex: 1,
              }}
              className="imgBG"
            />
          )}
          {this.state.size < 1023 && (
            <img
              src={mobileBG}
              className="center"
              style={{
                flex: 1,
              }}
              className="imgBG"
            />
          )}
          <div>
            <div
              style={{
                position: "absolute",
                left: "0%",
                top: "0%",
                width: this.state.size,
              }}
            >
              <div className="container">
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <span style={{ color: "#00B7D0", size: 18, fontWeight: 900 }}>
                    One
                  </span>
                  <span style={{ color: "#005662", size: 18, fontWeight: 900 }}>
                    Link
                  </span>
                </div>
                {/* web ui */}
                {this.state.size >= 1024 && (
                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ marginTop: 18 }}>
                        <span style={{ color: "#B4B4B4", size: 14 }}>
                          Welcome
                        </span>
                        <p style={{ fontWeight: 900, size: 18 }}>
                          Mr.{this.state.sender_firstName}{" "}
                          {this.state.sender_lastName}{" "}
                        </p>
                        {/* not paid ui */}
                        {!this.state.is_paid && (
                          <div>
                            <div
                              style={{
                                borderLeft: "8px solid #00B7D0",
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                paddingLeft: 5,
                                marginTop: 28,
                              }}
                            >
                              <span>
                                Please pay to{" "}
                                <span style={{ color: "#00B7D0" }}>
                                  {this.state.merchant_name}
                                </span>
                                <h5 style={{ fontSize: 32, fontWeight: 900 }}>
                                  E£ {this.numberWithCommas(this.state.amount)}
                                </h5>
                              </span>
                            </div>

                            <div style={{ marginTop: 18 }}>
                              <span
                                style={{
                                  color: "#00B7D0",
                                  fontWeight: 900,
                                  fontSize: 18,
                                }}
                              >
                                How Would You Like To Pay ?
                              </span>
                              {this.state.selectedValueErr && (
                                <p style={{ color: "#FF0000", fontSize: 12 }}>
                                  Please Select The Payment Type
                                </p>
                              )}
                            </div>

                            <CustomRadio
                              number="1"
                              label="Visa / Master Card"
                              option="megs"
                              name="radio"
                              onChange={this.handleChange}
                            />
                            {/* <CustomRadio
                              number="2"
                              label="Meeza"
                              option="upg"
                              name="radio"
                              onChange={this.handleChange}
                            />
                            <CustomRadio
                              number="3"
                              label="Vodavone Cash"
                              option="upg"
                              name="radio"
                              onChange={this.handleChange}
                            />
                            <CustomRadio
                              number="4"
                              label="Etisalat Cash"
                              option="upg"
                              name="radio"
                              onChange={this.handleChange}
                            />
                            <CustomRadio
                              number="5"
                              label="Orange Cash"
                              option="upg"
                              name="radio"
                              onChange={this.handleChange}
                            /> */}

                            <CustomCheckbox
                              onClick={(e) => {
                                console.log(e.target.checked);
                                if (e.target.checked == true) {
                                  this.setState({ checkBoxErr: "" });
                                } else {
                                  this.setState({
                                    checkBoxErr:
                                      "Please accept the terms and conditions",
                                  });
                                }
                                this.setState({ isCheckbox: e.target.checked });
                              }}
                              isChecked={this.state.isCheckbox ? true : false}
                              number="accept"
                              inline
                              label={
                                <span>
                                  I accept to the{" "}
                                  <a
                                    style={{ color: "#00B7D0" }}
                                    href={"/terms/" + this.state.transaction_id}
                                  >
                                    Terms & Conditions
                                  </a>{" "}
                                  and{" "}
                                  <a
                                    style={{ color: "#00B7D0" }}
                                    href={
                                      "/privacy/" + this.state.transaction_id
                                    }
                                  >
                                    Privacy Policy
                                  </a>
                                  .
                                  <br />
                                  {this.state.checkBoxErr != "" && (
                                    <span style={{ color: "red" }}>
                                      Please Accept The Terms And Conditions
                                    </span>
                                  )}
                                </span>
                              }
                            />

                            <div style={{ textAlign: "center" }}>
                              <CustomButton
                                fill
                                simple
                                round
                                style={{
                                  paddingLeft: 40,
                                  paddingRight: 40,
                                  backgroundColor: "#00B7D0",
                                }}
                                onClick={() => {
                                  this.pay();
                                }}
                              >
                                Pay
                              </CustomButton>
                            </div>
                          </div>
                        )}
                        {/* not paid ui */}

                        {/* paid ui */}
                        {this.state.is_paid && (
                          <div>
                            <div className="row" style={{ marginTop: 18 }}>
                              <div className="col-md-1">
                                <img src={successPayment} />
                              </div>
                              <div className="col-md-11">
                                <div style={{ marginLeft: 5 }}>
                                  <span
                                    style={{
                                      color: "#16BC00",
                                      fontSize: 18,
                                      fontWeight: 900,
                                    }}
                                  >
                                    Payment Successful
                                  </span>
                                  <p style={{ fontSize: 12 }}>
                                    Please check your email for payment details.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                borderLeft: "8px solid #00B7D0",
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                paddingLeft: 5,
                                marginTop: 28,
                              }}
                            >
                              <h5 style={{ fontSize: 32, fontWeight: 900 }}>
                                E£ {this.numberWithCommas(this.state.amount)}
                              </h5>
                              <span>
                                Paid To{" "}
                                <span style={{ color: "#00B7D0" }}>
                                  {this.state.merchant_name}
                                </span>
                              </span>
                              <br />
                              <span>
                                <img src={dateImg} />
                                <span style={{ marginLeft: 10 }}>
                                  {moment(this.state.paid_at).format(
                                    "MMM DD , YYYY"
                                  )}
                                </span>
                              </span>
                              <br />
                              <span>
                                <img src={timeImg} />
                                <span style={{ marginLeft: 10 }}>
                                  {moment(this.state.paid_at).format("hh:mm a")}
                                </span>
                              </span>
                            </div>
                          </div>
                        )}
                        {/* paid ui */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={appImg}
                        style={{ marginTop: 60, float: "right" }}
                      />
                    </div>
                  </div>
                )}
                {/* web ui */}

                {/* mobile ui */}
                {this.state.size < 1024 && (
                  <div>
                    <div style={{ marginTop: 18 }}>
                      <span style={{ color: "#B4B4B4", size: 14 }}>
                        Welcome
                      </span>
                      <p style={{ fontWeight: 900, size: 18 }}>
                        Mr.{this.state.sender_firstName}{" "}
                        {this.state.sender_lastName}{" "}
                      </p>
                      {/* not paid ui */}
                      {!this.state.is_paid && (
                        <div>
                          <div
                            style={{
                              borderLeft: "8px solid #00B7D0",
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              paddingLeft: 5,
                              marginTop: 28,
                            }}
                          >
                            <span>
                              Please pay to{" "}
                              <span style={{ color: "#00B7D0" }}>
                                {this.state.merchant_name}
                              </span>
                              <h5 style={{ fontSize: 32, fontWeight: 900 }}>
                                E£ {this.numberWithCommas(this.state.amount)}
                              </h5>
                            </span>
                          </div>

                          <div style={{ marginTop: 18 }}>
                            <span
                              style={{
                                color: "#00B7D0",
                                fontWeight: 900,
                                fontSize: 18,
                              }}
                            >
                              How Would You Like To Pay ?
                            </span>
                            {this.state.selectedValueErr && (
                              <p style={{ color: "#FF0000", fontSize: 12 }}>
                                Please Select The Payment Type
                              </p>
                            )}
                          </div>

                          <CustomRadio
                            number="1"
                            label="Credit / Debit Card"
                            option="megs"
                            name="radio"
                            onChange={this.handleChange}
                          />
                          <CustomRadio
                            number="2"
                            label="Meeza"
                            option="upg"
                            name="radio"
                            onChange={this.handleChange}
                          />
                          <CustomRadio
                            number="3"
                            label="Vodavone Cash"
                            option="upg"
                            name="radio"
                            onChange={this.handleChange}
                          />
                          <CustomRadio
                            number="4"
                            label="Etisalat Cash"
                            option="upg"
                            name="radio"
                            onChange={this.handleChange}
                          />
                          <CustomRadio
                            number="5"
                            label="Orange Cash"
                            option="upg"
                            name="radio"
                            onChange={this.handleChange}
                          />

                          <CustomCheckbox
                            isChecked={this.state.isCheckbox ? true : false}
                            number="accept"
                            onClick={(e) => {
                              console.log(e.target.checked);
                              if (e.target.checked == true) {
                                this.setState({ checkBoxErr: "" });
                              } else {
                                this.setState({
                                  checkBoxErr:
                                    "Please accept the terms and conditions",
                                });
                              }
                              this.setState({ isCheckbox: e.target.checked });
                            }}
                            inline
                            label={
                              <span>
                                I accept to the{" "}
                                <a
                                  style={{ color: "#00B7D0" }}
                                  href={"/terms/" + this.state.transaction_id}
                                >
                                  Terms & Conditions
                                </a>{" "}
                                and{" "}
                                <a
                                  style={{ color: "#00B7D0" }}
                                  href={"/privacy/" + this.state.transaction_id}
                                >
                                  Privacy Policy
                                </a>
                                .
                                <br />
                                {this.state.checkBoxErr != "" && (
                                  <span style={{ color: "red" }}>
                                    Please Accept The Terms And Conditions
                                  </span>
                                )}
                              </span>
                            }
                          />
                          <div style={{ textAlign: "center" }}>
                            <CustomButton
                              fill
                              simple
                              round
                              style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                backgroundColor: "#00B7D0",
                              }}
                              onClick={() => {
                                this.pay();
                              }}
                            >
                              Pay
                            </CustomButton>
                          </div>
                        </div>
                      )}
                      {/* not paid ui */}

                      {/* paid ui */}
                      {this.state.is_paid && (
                        <div>
                          <div className="row" style={{ marginTop: 18 }}>
                            <div className="col-md-1">
                              <img src={successPayment} />
                            </div>
                            <div className="col-md-11">
                              <div style={{ marginLeft: 5 }}>
                                <span
                                  style={{
                                    color: "#16BC00",
                                    fontSize: 18,
                                    fontWeight: 900,
                                  }}
                                >
                                  Payment Successful
                                </span>
                                <p style={{ fontSize: 12 }}>
                                  Please check your email for payment details.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              borderLeft: "8px solid #00B7D0",
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              paddingLeft: 5,
                              marginTop: 28,
                            }}
                          >
                            <h5 style={{ fontSize: 32, fontWeight: 900 }}>
                              E£ {this.numberWithCommas(this.state.amount)}
                            </h5>
                            <span>
                              Paid To{" "}
                              <span style={{ color: "#00B7D0" }}>
                                {this.state.merchant_name}
                              </span>
                            </span>
                            <br />
                            <span>
                              <img src={dateImg} />
                              <span style={{ marginLeft: 10 }}>
                                {moment(this.state.paid_at).format(
                                  "MMM DD , YYYY"
                                )}
                              </span>
                            </span>
                            <br />
                            <span>
                              <img src={timeImg} />
                              <span style={{ marginLeft: 10 }}>
                                {moment(this.state.paid_at).format("hh:mm a")}
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                      {/* paid ui */}
                    </div>
                  </div>
                )}
                {/* mobile ui */}

                <div style={{ marginTop: 20 }}>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <img src={masterImg} />
                    </li>
                    <li className="list-inline-item">
                      <img src={visaImg} />
                    </li>
                    <li className="list-inline-item">
                      <img src={meezImg} />
                    </li>
                    <li className="list-inline-item">
                      <img src={vodavoneImg} />
                    </li>
                    <li className="list-inline-item">
                      <img src={orangeImg} />
                    </li>
                    <li className="list-inline-item">
                      <img src={etisalatImg} />
                    </li>
                  </ul>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        href={"/terms/" + this.state.transaction_id}
                        style={{ color: "#B4B4B4", fontSize: 12 }}
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={"/privacy/" + this.state.transaction_id}
                        style={{ color: "#B4B4B4", fontSize: 12 }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                  <p style={{ color: "#B4B4B4", fontSize: 12 }}>
                    Copyright © 2020 Cloudx9 . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default PayTrans;
